import React from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";

import Layout from "../components/Layout";
import PortableText from "../components/PortableText";

export const query = graphql`
  {
    sanityAboutPage {
      pageTitle
      coverImage {
        asset {
          id
          originalFilename
          url
        }
        altText
      }
      intentions {
        title
        _rawBody
      }
      acknowledgements {
        title
        _rawBody
      }
    }
  }
`;

export default function About({
  data: {
    sanityAboutPage: { pageTitle, coverImage, intentions, acknowledgements }
  }
}) {
  return (
    <Layout title="About" page="about" url="/about">
      <h1 className="font-h1">{pageTitle}</h1>
      <Image image={coverImage} className="cover-image" />
      <h2 className="font-h2">{intentions.title}</h2>
      <PortableText blocks={intentions._rawBody} />
      <h2 className="font-h2">{acknowledgements.title}</h2>
      <PortableText blocks={acknowledgements._rawBody} />
    </Layout>
  );
}
